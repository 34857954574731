<template>
  <div id="app" class="has-text-centered">
    <div class="logo-container">
      <img alt="PersonalOTP.com" :src="require('@/assets/logo_potp.png')" class="logo" />
      <p class="logo-text">PersonalOTP.com</p>
    </div>
    <div class="columns is-centered">
      <div class="column is-half">
        <qrcode-vue :value="otpUri" :size="200" v-if="otpUri" class="mx-auto"></qrcode-vue><br/><br/>
        <p class="small">Can't scan the QR? Use this code instead: <strong class="highlight">{{ secret }}</strong>. Just make sure to send it safely and delete the message right after. To enter it manually, go to Google Authenticator and hit "Add > Enter a setup key".</p><br />
        <input type="text" v-model="label" placeholder="Unique name (e.g. Alice and Bob secret handshake)" v-on:keyup="generateOtpUri" class="input is-large"><br/><br/>
        <p class="title is-3">Current OTP: {{ currentOtp }}</p>
        <progress class="progress is-small" :value="progress" max="30"></progress>
      </div>
    </div>

    <section class="section">
      <div class="faq">
      <p class="title is-5 has-text-left">What is PersonalOTP?</p>
      <p class="has-text-left">PersonalOTP is a tool that lets two people create a shared secret in the form of a QR code, which they can then use to verify each other's identities over the phone, through text, or any other communication method. It's like a digital version of a secret handshake or a code word, but it uses technology similar to what's in your 2FA (two-factor authentication) app.</p>
    </div>

    <div class="faq">
      <p class="title is-5 has-text-left">How does it work?</p>
      <p class="has-text-left">Create Your Secret: You and your friend come up with a label for your secret and enter it into PersonalOTP to generate a QR code.<br>Scan the Code: Both of you scan this QR code using any 2FA app (like Google Authenticator).<br>Verify Each Other: Whenever you need to confirm it's really them, you both check the OTP (One-Time Password) your apps show. If the numbers match, you know it's safe to talk.</p>
    </div>

    <div class="faq">
      <p class="title is-5 has-text-left">Why should I use it?</p>
      <p class="has-text-left">In a world full of digital impersonation and scams, verifying the identity of the person you're communicating with is crucial. PersonalOTP provides an extra layer of security, ensuring that the person on the other end of the line is who they say they are.</p>
    </div>

    <div class="faq">
      <p class="title is-5 has-text-left">Do we both need a 2FA app?</p>
      <p class="has-text-left">Yes. Both parties need to have a 2FA app installed on their devices. These apps generate the OTPs (One-Time Passwords) that you'll compare to verify each other's identity.</p>
    </div>

    <div class="faq">
      <p class="title is-5 has-text-left">What if I lose my phone or delete my 2FA app?</p>
      <p class="has-text-left">If you lose access to your 2FA app, the shared secret used to generate your OTPs will be lost. You'll need to create a new shared secret with your contact. This ensures that even if someone finds your device, they can't use the old shared secret to impersonate you or your contact.</p>
    </div>

    <div class="faq">
      <p class="title is-5 has-text-left">What happens if someone else scans our QR code?</p>
      <p class="has-text-left">The QR code itself just helps set up the shared secret in your 2FA apps. Without ongoing access to either of your phones or the specific 2FA app, they won't be able to generate the current OTPs needed for verification. If you believe someone had access to your QR code or 2FA app, generate a new code.</p>
    </div>

      <div class="faq">
        <p class="title is-5 has-text-left">Should I keep the QR code secret or dispose of it after scanning?</p>
        <p class="has-text-left">Yes, it's crucial to keep the QR code secret or safely dispose of it after both parties have scanned it. The QR code is your gateway to setting up the shared secret that generates your OTPs. If it falls into the wrong hands, they could potentially set up a device to generate your OTPs, compromising your secure verification process. Treat it as you would any sensitive information: share it only with the intended person and ensure it's not accessible to others afterward.</p>
      </div>

      <div class="faq">
        <p class="title is-5 has-text-left">How do I safely share and dispose of the QR code?</p>
        <p class="has-text-left"><strong>Safe Sharing:</strong> When sharing the QR code, do so in a secure manner. This could mean generating and scanning the code in person, or if you're doing it remotely, use a secure, encrypted method of communication to send the QR code image.<br>
        <strong>Disposal:</strong> Once both of you have scanned the QR code, it's a good practice to delete the image from any device or communication channel it was shared through. If you printed the QR code, shred or thoroughly destroy the printout.</p>
      </div>

      <div class="faq">
        <p class="title is-5 has-text-left">Is PersonalOTP secure?</p>
        <p class="has-text-left">Yes, PersonalOTP uses TOTP (Time-Based One-Time Password) technology, which is widely regarded as a secure method for generating temporary codes that are hard to intercept or duplicate.</p>
      </div>

      <div class="faq">
        <p class="title is-5 has-text-left">Can I use PersonalOTP for official verification?</p>
        <p class="has-text-left">PersonalOTP is designed to verify the identity of people you already know and trust. It's not intended to replace official identification methods or to be used in legal or financial transactions.</p>
      </div>
    </section>
  </div>

  <footer class="footer">
    <p>An experiment by <a href="https://x.com/arturoleon">@arturoleon</a></p>
  </footer>
</template>

<script>
import { TOTP, Secret } from 'otpauth';
import QrcodeVue from 'qrcode.vue';
import 'bulma/css/bulma.css'

export default {
  name: 'App',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      otpUri: '',
      label: '',
      totp: null,
      currentOtp: '',
      progress: 0,
      secret: ''
    };
  },
  mounted() {
    this.generateOtpUri();
    this.startOtpRefresh();
  },
  methods: {
    generateOtpUri() {
      // Generate a new TOTP instance
      this.totp = new TOTP({
        issuer: 'PersonalOTP',
        label: this.label,
        algorithm: 'SHA1',
        digits: 6,
        period: 30,
        secret: new Secret({size: 10}),
      });
      // Generate a provisioning URI
      this.otpUri = this.totp.toString();

      this.secret = this.totp.secret.base32.match(/.{1,4}/g).join(' ');
      
      // Generate the first OTP
      this.generateOtp();
    },
    generateOtp() {
      // Generate the OTP from the TOTP instance
      if (this.totp) {
        let currentOtp = this.totp.generate()
        this.currentOtp = currentOtp.substring(0, 3) + ' ' + currentOtp.substring(3);
      }
    },
    startOtpRefresh() {
      // Calculate the time remaining until the next 30-second period
      const now = Date.now();
      const period = 30000;  // 30 seconds
      const timeUntilNextPeriod = period - (now % period);
      this.progress = 30 - (timeUntilNextPeriod / 1000);

      // Start the timer after the time until the next period
      setTimeout(() => {
        this.generateOtp();
        this.progress = 0;  // Reset the progress

        // Then start the interval
        this.otpRefreshInterval = setInterval(() => {
          this.generateOtp();
          this.progress = 0;  // Reset the progress
        }, period);
      }, timeUntilNextPeriod);

        // Start the progress interval
        this.progressInterval = setInterval(() => {
          this.progress++;  // Increase the progress
        }, 1000);
    },
  },
};
</script>

<style scoped>
.highlight {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 2px 4px;
  margin: 0 2px;
  border: 1px solid #d0d0d0;
}
.faq {
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 4px;
  box-shadow: none;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
.logo-text {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 4em;
  color: #22205b;
}
.logo {
  height: 8em;
  max-width: 90%;
  margin-right: 1em;  /* Add some space between the logo and the text */
}
#app{
  padding-top: 2em;
  max-width: 90%;
  margin: auto
}
.input, .progress {
  margin: 0 1em; /* Add horizontal margin to the input and progress bar */
}
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  color: black;
  text-align: center;
  padding: 2em 1em;
}
@media (max-width: 600px) {
  .logo {
    height: 4em; /* Make the logo smaller */
  }

  .input, .progress {
    margin: 0 0.5em; /* Reduce the horizontal margin */
  }

  .logo-text {
    font-size: 2.5em;
  }
}
</style>